.filledButton {
  transition: box-shadow 150ms, transform 150ms;
  box-shadow: 0 0 0 0 var(--tw-shadow-color);
  transform: translate(0, 0);
}

@media (hover: hover) {
  .filledButton:not([disabled]) {
    &:hover {
      box-shadow: -0.3rem 0.3rem 0 0 var(--tw-shadow-color);
      transform: translate(0.3rem, -0.3rem);
    }

    &:active {
      box-shadow: -0.2rem 0.2rem 0 0 var(--tw-shadow-color);
      transform: translate(0.2rem, -0.2rem);
    }
  }
}
