.outlinedButton {
  transition: transform 150ms;
  z-index: 1;
  display: block;
  position: relative;
  transform: translate(0, 0);
}

@media (hover: hover) {
  .outlinedButton {
    &:hover {
      transform: translate(0.3rem, -0.3rem);
    }

    &:active {
      transform: translate(0.2rem, -0.2rem);
    }
  }
}

.outlinedButtonContainer {
  position: relative;

  &:before {
    z-index: 0;
    content: '';
    @apply block rounded-lg border-2 absolute inset-0 pointer-events-none select-none;
  }
}
